$euiColorPrimary: #1595d3;
$euiColorAccent: #f88e0a;
$euiColorDanger: #c13930;
$euiColorWarning: #d76e10;
$euiColorSuccess: #00805e;
$euiBorderRadius: 5px;

@import url('https://rsms.me/inter/inter-ui.css');
* {
	font-family: 'Inter UI', Arial, sans-serif;
}

.App {
	.dashboard {
		.euiPage {
			// background-image: url(../images/bg-pattern.png);
			background-color: #FCFCFC;
			// background-repeat: repeat;
			min-height: calc(100vh - 59px);

			&.hasSticky {
				.euiPageHeader.sticky {
					position: -webkit-sticky;
					position: sticky;
					top: 59px;
					background-color: #fafbfd;
					box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
					z-index: 999;
				}
			}

			.euiPageHeader {
				padding: 15px 30px 10px;
			}

			.euiPageBody {
				padding: 15px;
			}
		}
	}

	& > .euiPage {
		// background-image: url(../images/bg-pattern.png);
		background-color: #FCFCFC;
		// background-repeat: repeat;
		min-height: 100vh;

		.euiPageBody {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 15px;
		}
	}

	.euiFieldText,
	.euiFieldPassword,
	.euiFieldNumber,
	.euiTextArea,
	.euiComboBox .euiComboBox__inputWrap {
		border-radius: $euiBorderRadius;
	}
}

/*** EUI HEADER ***/
.euiHeader {
	height: 59px;

	&.euiHeader--dark {
		background-color: $euiColorPrimary;

		.euiHeaderSectionItem:after {
			background: #fff;
		}
	}

	.brand-container {
		justify-content: center;
		height: 59px;
		padding-left: 12px;
		padding-right: 12px;

		img {
			height: 30px;
			width: auto;
		}
	}

	.main-menu-button {
		height: 59px;
		width: 59px;
		border-radius: 0;
	}

	.points {
		display: flex;
		align-items: center;
		font-size: 13px;
	}

	.euiHeaderSectionItemButton {
		display: flex;
		align-items: center;
		margin: 0 5px;

		&:hover, &:focus {
			background-color: rgba(0, 119, 204, 0.1);
		}
	}

	.euiButtonEmpty__text {
		display: flex;
	}
}


/*** EUI BODY ***/
.euiBody--headerIsFixed {
	padding-top: 59px;

	.euiCollapsibleNav {
		top: 59px;
		height: calc(100% - 59px);
	}
}

.euiBody--collapsibleNavIsDocked {
	.euiPage.hasSticky {
		padding-top: 0;
	}
}

/*** EUI OVERLAY MASK ***/
.euiOverlayMask {
	inset-block-start: 0 !important;
	padding-block-end: 0 !important;
}

.euiOverlayMask .fullScreenModal {
	min-height: 100vh;
	min-width: 100vw;
	max-inline-size: none;
	max-block-size: none;
}

/*** SIDEBAR ***/
.sideBar {
	@media screen and (min-width: 766.5px) {
		min-width: 500px;
		max-width: 25%;
	}
}

/*** EUI TABS ***/
.euiTabs {
	min-height: 40px;
	max-height: 60px;
	margin-top: -5px;

	.euiTab {
		&.euiTab-isSelected {
			.euiTab__content {
				font-weight: 600;
			}
		}

		.euiTab__content {
			font-size: 16px;
			font-weight: 500;
			line-height: 1.5;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

/*** EUI TITLE***/
.euiTitle {
	font-weight: 400;

	.subtitle {
		font-style: italic;
		font-weight: 400;
		// color: $euiTextSubduedColor;
	}
}

/*** ORDER SCHEDULE FORM ***/
.radioGroupItem {
	display: flex !important;
	align-items: center !important;
	gap: 10px !important;
	margin-bottom: 10px;

	.euiFlexItem {
		height: 30px;
		justify-content: center;
	}
}
#repeatCount {
	height: 30px;
	width: 60px;
	text-align: center;
}
#repeatEndDate {
	height: 30px;
	width: 170px;
}

/*** OTHERS ***/
.euiSuperSelectControl {
	border-radius: $euiBorderRadius;
}

.danger-button-outlined {
	border: 1px solid $euiColorDanger !important;
}

.underlined {
	text-decoration: underline;
}

.table-actions {
	width: 100%;
	flex-wrap: wrap;
}

.floating-rx-button {
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 40px;
	right: 40px;
	background-color: $euiColorPrimary;
	color: #fff;
	border-radius: 50px;
	text-align: center;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&:hover {
		box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
	}
}

.footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	> div {
		width: 100%;
	}
}

.thumbnail-img {
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 5px;
	cursor: pointer;

	&:hover {
		box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
	}
}

.signature-tooltip {
	padding: 15px 15px 10px 30px;
	list-style-type: circle;;

	li {
		margin-bottom: 10px;
	}
}

@media screen and (max-width: 425.5px) {
	.euiHeader {
		.brand-container {
			justify-content: center;
			padding-left: 10px;
			padding-right: 10px;

			img {
				height: 25px;
				width: auto;
			}
		}

		.main-menu-button {
			height: 30px;
			width: 40px;
		}

		.euiHeaderSectionItemButton {
			margin: 0 3px;
		}
	}
}

@media screen and (max-width: 320.5px) {
	.euiHeader > div:last-of-type > div:first-of-type {
		display: none;
	}
}

/*** EUI TOAST ***/
// .euiToast {
// 	color: #fff;
// 	border-top: 0;
// 	border-radius: 5px;

// 	&.euiToast--success {
// 		background-color: #15bb20;
// 	}

// 	&.euiToast--danger {
// 		background-color: #e94a38;
// 	}

// 	.euiToastHeader__title {
// 		color: #fff;
// 	}

// 	svg {
// 		fill: #fff;
// 	}
// }

/*** NOTIFICATIONS ***/
.notification-scrolling {
	max-width: 400px;
	max-height: 400px;
	overflow-y: auto;
	padding: 0 !important;

	> div {
		padding: 0 !important;
	}

	.euiPanel {
		border-radius: 0;
	}

	.euiPanel.euiPanel--isClickable:hover,
	.euiPanel.euiPanel--isClickable:focus {
		transform: translateY(0px);
	}
}

.notification-unread {
	border-radius: 0px;
	background-color: #e8eaeb;
}

.notification-read {
	border-radius: 0px;
}

.notification-template {
	margin-bottom: 8px;

	&.title {
		font-weight: bold;
	}

	&.date {
		float: right;
		color: #707070;
	}
}

/*** SLICK ***/
.slick-track {
	display: flex;
}

.slick-track .slick-slide {
	display: flex;
	height: auto;
	align-items: center;
	justify-content: center;
	margin: 0 27px;
}

.slick-track .slick-list {
	margin: 0 -27px;
}

.slick-dots li button {
	margin: 0;
	height: auto;
	width: 100%;
}

.slick-dots li button.tab {
	clear: both;
	display: block;
}

.slick-dots li button img {
	width: 100%;
	max-width: 80px;
	height: auto;
}

.slick-dots li button:before {
	content: '';
	border: 1px solid #f00;
	display: block;
	width: 100%;
	max-width: 150px;
	overflow: hidden;
	float: none;
	position: absolute;
	width: 100%;
	height: 100%;
}

.slick-thumbs {
	position: absolute;
	left: -9999px;
}

/*** PRINT ***/
.print {
	display: none !important;
}

@media print {
	#root {
		display: none;
	}

	.print {
		display: block !important;
		z-index: 1000;
		min-height: 100vh;
		width: 100%;
		background-color: #fff;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 20px;
	}
}

/*** REGISTRATION ***/
.registration {
	&.form-container {
		width: 800px;
		padding: 0;
	}

	.right-column {
		background-color: #f9f9f9;
		padding: 20px;
		border-top-right-radius: $euiBorderRadius;
		border-bottom-right-radius: $euiBorderRadius;
		justify-content: center;

		.euiToolTipAnchor {
			display: inline;
		}

		.euiFieldText:focus {
			background-color: white;
			background-image: linear-gradient(to top,
					$euiColorAccent,
					$euiColorAccent 2px,
					transparent 2px,
					transparent 100%);
			background-size: 100% 100%;
			box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
				0 4px 4px -2px rgba(152, 162, 179, 0.2),
				inset 0 0 0 1px rgba(35, 66, 136, 0.1);
		}

		.euiButton--primary.euiButton--fill {
			&:disabled {
				opacity: 0.7;
			}
		}

		.euiCheckbox .euiCheckbox__input:checked+.euiCheckbox__square {
			background-color: $euiColorAccent;
			border-color: $euiColorAccent;
		}

		.euiLink.euiLink--ghost {
			text-decoration: underline;
		}
	}

	.invalidFormError {
		border: 1px solid red;
	}

	.invalidFormErrorTextColor {
		color: red;
		font-weight: bold;
	}
}

@media only screen and (max-width: 768px) {
	.mobile-padding-bottom-10 {
		padding-bottom: 10px;
	}

	.prescription-table, .patient-order-list-table {
		div {
			table {
				tbody {
					tr {
						padding: 8px !important;
						td:last-child {
							width: 100%;
						}
					}
				}
			}
		}
	}
  .__pending-order-btn{
	width: 110px;
	margin-left: -12px;
  }
}

@media only screen and (max-width: 425.5px) {
	.euiPanel {
		> section,
		> section > div {
			padding: 0;
		}
	}
}
